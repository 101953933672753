<template>

<!--Main Navigation-->
<header>
  <!-- Navbar -->
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <!-- Container wrapper -->
    <div class="container-fluid">
      <!-- Toggle button -->
      <button
        class="navbar-toggler"
        type="button"
        data-mdb-toggle="collapse"
        data-mdb-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i class="fas fa-bars"></i>
      </button>

      <!-- Collapsible wrapper -->
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <!-- Navbar brand -->
        <router-link class="navbar-brand mt-2 mt-sm-0" to="/">
          <img
            src="https://cdn.simplexitydynamics.com/icons/cia/favicon-32x32.png"
            height="32"
            alt="Hutt Studio"
            loading="lazy"
          />
        </router-link>
      </div>
      <!-- Collapsible wrapper -->

      <!-- Right elements -->
      <div class="d-flex align-items-center">
        <!-- Icon -->
        <span class="me-3">
          <router-link class="nav-link" to="/"><i class="fas fa-home me-3"></i></router-link> 
        </span>
        <span class="me-3">
          <router-link class="nav-link" to="/rss"><i class="fas fa-rss me-3"></i></router-link>  
        </span>
        <span class="me-3">
          <a href="https://articles.codersinaction.com" target="_blank" rel="noreferrer noopener">Articles</a>
        </span>  

        
      </div>
      <!-- Right elements -->
    </div>
    <!-- Container wrapper -->
  </nav>
  <!-- Navbar -->

      <!-- Background image -->
      <!-- <div id="intro" class="bg-image vh-100 shadow-1-strong"> -->
        <div id="intro" class="ratio ratio-16x9">
          <video style="min-width: 100%; min-height: 100%;" playsinline autoplay muted loop>
            <source class="h-100" src="https://cdn.simplexitydynamics.com/vids/nodes.mp4" type="video/mp4" />
          </video>
          <div class="mask-tn-1">
            <div class="container d-flex align-items-center justify-content-center text-center h-100">
              <div class="text-white">
                <h1 class="mb-3">Coders In Action</h1>
                <h5 class="mb-4">Fueling Progress: Where code and research unite, with a hope that collaboration will thrive!</h5>
                
              </div>
            </div>
          </div>
      </div>
      <!-- Background image -->
  
</header>






</template>

<script>
export default {
  name: 'NavTop',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #2d1855;
}
/* Height for devices larger than 576px */
@media (min-width: 992px) {
  #intro {
    /* margin-top: -58.59px; */
    margin-top: -5px;
  }
}

.bg-tn-1 {
  background: linear-gradient(
            90deg,
            rgba(20, 5, 43, 0.7),
            rgba(9, 21, 75, 0.301) 100%
          );
}

.mask-tn-1 {
  background: linear-gradient(
            45deg,
            rgba(64, 69, 73, 0.7),
            rgba(25, 16, 39, 0.7) 100%
          );
}

.navbar .nav-link {
  color: #ffffff !important;
}


</style>
