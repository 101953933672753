<template>
  <div class="container">
    

    <!-- Background image -->
    <div
        class="d-flex justify-content-center align-items-center"
        style="height: 100vh"
      >
        <div class="text-center">
          <img
            class="mb-4"
            src="https://cdn.simplexitydynamics.com/icons/coders-in-action-v4_black.png"
            style="width: 208px; height: 176px"
          />
          <h5 class="mb-3">{{ msg }}</h5>
          <p class="mt-5 mb-3">
            Building (1) technical documentations for Tyche Net, (2) and products and solutions designs for Simplexity 101 and Hutt Studio.   <br/>
            <i class="fa-solid fa-road fa-2xl"></i> 
            Doing these while discovering capabilities of 
            <a href="https://s101.codersinaction.com/" class="text-reset" target="_blank" rel="noreferrer noopener">Nuxt.js</a>, 
            <a href="https://articles.codersinaction.com/" class="text-reset" target="_blank" rel="noreferrer noopener">Next.js</a>, 
            React Native, and Flutter.
          </p>
          <p class="mt-5 mb-3">
            Just managing expectations, these will be pre-sales type docs. <i class="fa-regular fa-face-smile fa-flip"></i>
          </p>
        </div>
      </div>
    <!-- Background image -->
    

  </div>

</template>
  
<script>
export default {
  name: 'HomePage',
  props: {
    msg: String
  }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

/* The actual timeline (the vertical ruler) */
.main-timeline-4 {
  position: relative;
}
/* Height for devices larger than 576px */
@media (min-width: 992px) {
  #intro {
    margin-top: -58.59px;
  }
}
</style>