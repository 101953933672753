import { 
    createRouter, 
    createWebHashHistory 
} from "vue-router";

import HomePage from '../components/HomePage';
import RssPagePage from '../components/RssPage';


export default createRouter({
    history: createWebHashHistory(),
    routes: [{
        path: '/',
        name: 'Home',
        component: HomePage,
    },{
        path: '/rss',
        name: 'rss',
        component: RssPagePage,
    }],
});